const ProjectKey = "tq-project";

let defaultProject = {
  componentList: [],
  config: {
    backgroundColor: "",
    pageTitle: "",
    shareTitle: "",
    shareContent: "",
    shareImg: "",
    messageUid: "",
  },
};
export function getProject() {
  let str = localStorage.getItem(ProjectKey);
  return str ? JSON.parse(str) : JSON.parse(JSON.stringify(defaultProject));
}

export function settingProject(project) {
  return localStorage.setItem(ProjectKey, JSON.stringify(project));
}

export function removeProject() {
  return localStorage.removeItem(ProjectKey);
}

// 组件内容校验
export function verification(_this, activity, componentList) {
  if (componentList.length > 0) {
    let result = _suspendVerifyHandler(_this, componentList);
    result = result.verify ? _timerVerifyHandler(_this, activity, componentList) : result;
    return result;
  }
  return { verify: true };
}

// 悬浮组件_校验
function _suspendVerifyHandler(_this, componentList) {
  // 同一个专题页内只能有一个底部固定悬浮，若存在多个，保存时提示
  const bottomRepeatList = componentList.filter(
    (v) => v.component === "Suspend" && v.value.suspendType === "bottom"
  );
  if (bottomRepeatList.length >= 2) {
    return { verify: false, msg: "只能包含一个底部悬浮组件" };
  }
  // 左右悬浮组件,相同位置只能有一个
  const leftRightList = componentList.filter(
    (v) => v.component === "Suspend" && v.value.suspendType !== "bottom"
  );
  const repeatList = leftRightList.filter((v, i, arr) => {
    const repeatIndex = arr.findIndex((valuef, indexf) => {
      return (
        indexf !== i &&
        valuef.value.position === v.value.position &&
        valuef.value.suspendType === v.value.suspendType
      );
    });
    return repeatIndex !== -1;
  });
  if (repeatList.length > 0) {
    componentList.forEach((element) => {
      if (
        element.component === "Suspend" &&
        repeatList.findIndex((v) => v.id === element.id) !== -1
      ) {
        _this.$set(element.routine, "error", true);
      }
    });
    return { verify: false, msg: "左右悬浮组件,相同位置只能有一个" };
  }

  return { verify: true };
}

function _timerVerifyHandler(_this, activity, componentList) {
  for (const iterator of componentList) {
    if (iterator.component === "Timer") {
      // 判断是否有未填写的截止时间
      for (let index = 0; index < iterator.value.timeConfig.length; index++) {
        const currentValue = iterator.value.timeConfig[index];
        if (!currentValue.endTime) {
          return {
            verify: false,
            msg: `${iterator.name}_${iterator.routine.modelName}： 【波段${
              index + 1
            }】未设置截止时间，请检查！`,
          };
        } else {
          // 判断各时间波段的截止时间不能超过活动结束时间
          const activityEndTime = new Date(activity.end_time).getTime();
          if (currentValue.endTime > activityEndTime) {
            return {
              verify: false,
              msg: `${iterator.name}_${iterator.routine.modelName}：【波段${
                index + 1
              }】截止时间晚于活动结束时间，请检查！(活动结束时间:${activity.end_time})`,
            };
          }
        }
      }

      // 判断个波段时间顺序
      for (let index = 0; index < iterator.value.timeConfig.length - 1; index++) {
        const currentValue = iterator.value.timeConfig[index];
        const nextValue = iterator.value.timeConfig[index + 1];
        if (currentValue.endTime > nextValue.endTime) {
          return {
            verify: false,
            msg: `${iterator.name}_${iterator.routine.modelName}： 【波段${
              index + 1
            }】截止时间晚于【波段${index + 2}】的截止时间，请检查！`,
          };
        }
      }
    }
  }

  return { verify: true };
}
