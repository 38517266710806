import Vue from "vue";

// 注册所有全局组件
register(require.context("@/components/global", true, /.vue/));

// 注册所有配置组件
register(require.context("@/schema-template", true, /.vue/));

// 注册所有物料
// register(require.context('../../../mall-cook-template/src/widgets', true, /.vue/))

// 获取所有物料初始数据与schema数据
registerComponentsSchema();

// /**
//  * 注册对应包下所有组件
//  * @param {*} path 包路径
//  */
function register(context) {
  context.keys().forEach((cnt) => {
    const component = context(cnt);
    console.log(component);
    let ctrl = component.default || component;
    console.log(ctrl);
    let a = ctrl.name;
    let b = ctrl;

    // 注册组件
    Vue.component(a, b);
  });
}

// 获取所有自定义组件schema
function registerComponentsSchema() {
  const files = require.context("../../../ui/src/widgets", true, /component.json$/);
  let fields = {};
  let initializing = {};
  let modelTypeSet = new Set();
  files.keys().forEach((key) => {
    const [, name] = key.split("/");
    let config = { component: name, ...files(key) };
    if (config.enable) {
      fields[name] = config.fields;
      initializing[name] = initDefaulValue(config);
      modelTypeSet.add(config.modelType);
    }
  });
  Vue.prototype.$fields = fields;
  console.log("fields", fields);
  Vue.prototype.$initializing = initializing;
  console.log("initializing", initializing);
  console.log("modelTypeSet: ", modelTypeSet);
  Vue.prototype.$modelType = Array.from(modelTypeSet);
}

// 初始化组件初始数据
function initDefaulValue(config) {
  let { component, name, icon, fields, modelType } = config;
  let temp = { component, name, icon, modelType };
  setDefaultValue(fields, temp);
  return temp;
}

// 递归设置各层级初始数据
function setDefaultValue(fields, initializing) {
  for (let key in fields) {
    let { type, value, child } = fields[key];
    if (type == "object") {
      initializing[key] = {};
      child && setDefaultValue(fields[key].child, initializing[key]);
    } else {
      initializing[key] = value;
    }
  }
  return initializing;
}
