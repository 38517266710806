<template>
  <div>
    <JumpType v-model="mValue" :options="mOptions" />
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import JumpType from "@/components/JumpType";

export default {
  name: "SchemaJumpData",
  mixins: [schemaMixin],

  components: {
    JumpType,
  },
  data() {
    return {};
  },
  methods: {},
  // created() {
  //   console.log("options.types??bbbb", this.options.types);
  //   console.log("mOptions??bbbb", this.mOptions);
  // },
};
</script>
