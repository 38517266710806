<template>
  <div>
    <!-- 魔方行数选择 -->
    <!-- <config-item v-show="mValue.model == 'custom'" label="魔方行数">
      <el-select v-model="mValue.row" placeholder="请选择" size="small" @change="changeRow">
        <el-option v-for="key in 10" :key="key" :label="key + '行'" :value="key"> </el-option>
      </el-select>
    </config-item>-->

    <!-- 魔方模板选择 -->
    <SchemaSelect
      class="mt15 mb20"
      :label="label"
      v-model="mValue.model"
      :data="models"
      @change="changeModel"
    ></SchemaSelect>

    <!-- 魔方布局 -->
    <template>
      <div class="f-red f13 mb15 pl10 pr10">配置图片数据</div>
      <div class="f-grey f12 mb15 pl10 pr10">点击选择图片区域</div>

      <div class="pl10 pl10">
        <cap-cube-layout
          ref="layout"
          :row="mValue.row"
          :model="mValue.model"
          v-model="mValue.list"
          @onCuurIndex="onCuurIndex"
        ></cap-cube-layout>
      </div>
    </template>
    <div class="f-red f13 mb15 pl10 pr10"></div>
    <div class="f-grey f12 mb15 pl10 pr10">上传图片</div>

    <div v-show="formShow">
      <div class="nav ml10 mr10 mb20">
        <ImgpondMy :count="1" v-model="activeItem.image" />
      </div>
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <div class="f-grey f12 mb15 pl10 pr10">名称(必填)</div>
      <div class="nav ml10 mr10 mb20">
        <el-input v-model="activeItem.productName" size="small" placeholder="请输入"></el-input>
      </div>
      <JumpType
        v-if="jumpTypeShow"
        v-model="activeItem.jumpData"
        :options="{ addCourseBtn: true }"
      />
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import CapCubeLayout from "./CapCubeLayout";
import { initialModels, modelOptions } from "./config";
import JumpType from "@/components/JumpType";

export default {
  name: "SchemaCapCube",

  mixins: [schemaMixin],

  components: {
    CapCubeLayout,
    JumpType,
  },
  data() {
    return {
      formShow: false,
      jumpTypeShow: false,
      modelOptions,
      models: [],
      activeItem: {
        image: "",
        jumpData: {
          name: "",
          type: "",
          value: "",
        },
      },
    };
  },
  // inject: ["radioselcet"],
  created() {
    this.init();
    setTimeout(() => {
      console.log("mValue:2233334455 ", this.mValue);
    }, 100);
  },
  // watch: {
  //   radioselcet: {
  //     handler(newVal, oldVal) {
  //       console.log("radioselcet newVal ******** ", newVal);
  //       console.log("radioselcet oldVal ********* ", oldVal);
  //     },
  //   },
  // },
  methods: {
    init() {
      this.models = this.modelOptions.filter((v) => {
        return this.options.model.indexOf(v.value) > -1;
      });
      console.log("this.models :223333 ", this.models);
    },
    // 切换模型
    changeModel(model) {
      if (model) {
        this.formShow = false;
        // 设置模板对应行数
        let target = this.modelOptions.find((m) => m.value == model);
        this.$set(this.mValue, "row", target.row);

        // 重置模板
        this.$refs.layout.reset();

        // 设置模板对应初始数据
        if (model == "custom") {
          this.$set(this.mValue, "list", []);
        } else {
          // const flag = this.mValue.list.some((value) => value.image);
          this.$set(this.mValue, "list", this._.cloneDeep(initialModels[model]));
        }
      }
    },

    // 切换行数
    changeRow() {
      this.$refs.layout.reset();
    },

    onCuurIndex(itme) {
      this.formShow = true;
      this.activeItem = itme;
      this.jumpTypeShow = true;
    },
  },
};
</script>
