export default {
  number: "SchemaNumber",
  string: "SchemaString",
  select: "SchemaSelect",
  switch: "SchemaSwitch",
  radio: "SchemaRadio",
  color: "SchemaColor",
  upload: "SchemaUpload",
  jump: "SchemaJump",
  time: "SchemaTime",
  goods: "SchemaGoods",
  object: "SchemaObject",
  array: "SchemaArray",
  cube: "SchemaCapCube",
  course: "SchemaCourse",
  jumpData: "SchemaJumpData",
  prize: "SchemaPrize",
  popup: "SchemaPopup",
  editor: "SchemaEditor",
};
