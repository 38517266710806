<template>
  <configure-item :label="label">
    <el-input v-model="mValue" v-bind="mOptions" size="small"></el-input>
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaString",
  mixins: [schemaMixin],
};
</script>
