<template>
  <configure-item :label="label">
    <el-date-picker
      v-model="valueTmp"
      type="datetime"
      v-bind="mOptions"
      size="small"
      style="width: 100%"
      :picker-options="pickerOptions"
      @change="changeHandler"
    >
    </el-date-picker>
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaTime",
  mixins: [schemaMixin],
  computed: {
    pickerOptions() {
      if (this.options.needPicker) {
        let now = new Date();
        let lastDay = now.getTime() - 86400000;
        return {
          disabledDate: function (e) {
            if (e.getTime() > lastDay) {
              return false;
            }
            return true;
          },
        };
      }
      return false;
    },
  },
  created() {
    this.valueTmp = this.mValue;
  },
  data() {
    return {
      defaultOptions: {
        placeholder: "选择日期时间",
        format: "yyyy-MM-dd HH:mm:ss",
      },
      valueTmp: "",
    };
  },
  methods: {
    changeHandler(v) {
      this.mValue = v;
    },
  },
};
</script>
