<template>
  <el-dialog title="课程设置" :visible.sync="dialogFormVisible" append-to-body>
    <el-button size="small" type="primary" icon="el-icon-plus" @click="$refs.courseList.open()"
      >添加课程</el-button
    >

    <el-table :data="tableData" border style="margin: 20px auto">
      <el-table-column
        min-width="120"
        prop="number"
        label="产品ID"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="产品名称"
        min-width="300px"
        show-overflow-tooltip
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        min-width="100"
        prop="price"
        label="产品价格"
        align="center"
        header-align="center"
        :formatter="priceFormatter"
      >
      </el-table-column>
      <el-table-column label="操作" min-width="60px" align="center" header-align="center">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="handleDelete(scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="width: 100%; text-align: center">
      <el-button type="primary" @click="confirmHandler">确 定</el-button>
    </div>

    <CourseList ref="courseList" :options="options" @afterSelect="handleAfterSelect" />
  </el-dialog>
</template>

<script>
import CourseList from "./CourseList.vue";
export default {
  name: "CourseSelect",
  components: {
    CourseList,
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: {
      type: Object,
      default: () => {
        return {
          batch: false,
        };
      },
    },
  },

  data() {
    return {
      tableData: [],
      tableLoading: true,
      dialogFormVisible: false,
    };
  },
  methods: {
    open() {
      this.tableData = this.value;
      this.dialogFormVisible = true;
    },
    handleAfterSelect(v) {
      this.tableData = v;
    },
    confirmHandler() {
      this.$emit("input", this.tableData);
      this.dialogFormVisible = false;
    },
    handleDelete(index) {
      this.$confirm("", "确定要删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
      })
        .then(() => {
          this.tableData.splice(index, 1);
        })
        .catch(() => {});
    },
    priceFormatter(row) {
      if (row.price) {
        return Number(Number(row.price) / 100).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: center;
}
.el-upload__tip {
  margin-top: -7px;
}
.el-form-item {
  margin-bottom: 14px !important;
}
</style>
