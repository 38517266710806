import axios from "@/utils/request";

// 获取oss
export function aliSts() {
  return axios.get("/admin_api/v1/common/ali_sts");
}

// 创建人列表
export function getCreatorList() {
  return axios.get("/admin_api/v1/common/creator");
}

// 课程列表
export function getCourseList(params) {
  return axios.get("/admin_api/v1/common/products", {
    params,
  });
}

// 优惠券列表
export function getCouponList(params) {
  return axios.get("/admin_api/v1/common/coupons", { params });
}

// 分类列表
export function getCategory(params) {
  return axios.get("/admin_api/v1/common/classifies", {
    params,
  });
}
// 提交页面信息
/**
 *
 * @param {*} id  string
 * @param {*} data {content,extra}
 * @returns
 */
export function submitPageInfo(id, data) {
  return axios.post(`/admin_api/v1/activity/${id}/content`, data);
}

// 获取页面详情
export function getPageInfo(id) {
  return axios.get(`/admin_api/v1/activity/${id}/content`);
}

//

// 搜索组织架构
export function getUserTree(params = { is_fetch_all: true, type: "STAFF" }) {
  return axios.get(`/admin_api/v1/ww_user/tree/nodes`, { params });
}
