<template>
  <ul>
    <li v-for="(s, key, index) in schema" :key="index">
      <component
        :key="index"
        :is="typeToComponent[s.type]"
        v-model="value[key]"
        v-bind="s"
        :schema="s"
      >
        <schema-template v-if="s.child" :schema="s.child" :value="value[key]"></schema-template>
      </component>
    </li>
  </ul>
</template>

<script>
import typeToComponent from "@/config/schema-template";

export default {
  name: "schema-template",

  provide() {
    return {
      mode: this,
    };
  },
  props: {
    schema: {
      typeof: Object,
      default: () => {},
    },
    value: {
      typeof: Object,
      default: () => {},
    },
  },

  data() {
    return {
      typeToComponent,
    };
  },
  created() {
    setTimeout(() => {
      console.log("schema44444444", this.schema);
      console.log("value555555", this.value);
    }, 300);
  },
};
</script>
