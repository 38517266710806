<template>
  <el-dialog
    :width="dialogWidth"
    top="5vh"
    title="添加课程"
    :visible.sync="innerVisible"
    append-to-body
  >
    <el-form :inline="true" :model="serachForm" class="demo-form-inline">
      <el-form-item style="width: 150px">
        <el-input v-model="serachForm.keyword" placeholder="请输入课程ID、名称或年份"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="medium" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      ref="table"
      :data="tableData"
      element-loading-text="加载中 ..."
      border
      header-align="center"
      highlight-current-row
      @select="handleSelect"
      @select-all="handleSelect"
      @current-change="handleCurrentChange"
    >
      <el-table-column v-if="options.limit > 1" type="selection" width="39"> </el-table-column>
      <el-table-column type="index" align="center" label="序号" width="50" />
      <el-table-column label="课程ID" prop="number" align="center" header-align="center" />
      <el-table-column label="课程名称" prop="name" align="center" header-align="center" />
      <el-table-column label="适用年份" prop="year" align="center" header-align="center" />
      <el-table-column
        label="课程原价"
        prop="price"
        align="center"
        header-align="center"
        :formatter="priceFormatter"
      />
    </el-table>
    <div class="pagination-container">
      <pagination
        :total="count"
        :page.sync="pagination.page"
        :limit.sync="pagination.size"
        @pagination="fetchData"
      />
    </div>
    <div style="width: 100%; text-align: center">
      <el-button type="primary" @click="confirmHandler">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCourseList } from "@/api/common";
export default {
  name: "CourseList",
  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogWidth: "80%",
      innerVisible: false,
      serachForm: {
        keyword: "",
      },
      tableData: [],
      count: 0,
      pagination: {
        page: 1,
        size: 10,
      },
      selectedData: [],
      tmpData: [],
      selectResult: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    open() {
      this.innerVisible = true;
    },
    fetchData() {
      const requestBody = { ...this.serachForm, ...this.pagination };
      getCourseList(requestBody).then((res) => {
        this.count = res.data.count;
        this.tableData = res.data.results;
        this.handleSelected();
      });
    },
    search() {
      this.pagination.page = 1;
      this.fetchData();
    },
    confirmHandler() {
      this.$emit("afterSelect", this.selectResult);
      this.innerVisible = false;
    },
    handleCurrentChange(currentRow) {
      if (currentRow && !this.options.batch) {
        this.selectResult = Array.of(currentRow);
      }
    },
    handleSelect(v) {
      if (this.options.batch) {
        this.selectedData = [];
        v.forEach((v) => {
          this.selectedData.push(v);
        });
        this.tmpData[this.pagination.page - 1] = this.selectedData;
        let result = [];
        this.tmpData.forEach((arr) => {
          if (arr) {
            arr.forEach((v) => {
              result.push(v);
            });
          }
        });
        this.selectResult = result;
      }
    },
    handleSelected() {
      const arr = this.tmpData[this.pagination.page - 1];
      if (arr) {
        this.$nextTick(() => {
          arr.forEach((element) => {
            this.tableData.forEach((v, i) => {
              if (element.uuid === v.uuid) {
                this.$refs.table.toggleRowSelection(this.tableData[i], true);
              }
            });
          });
        });
      }
    },
    priceFormatter(row) {
      if (row.price) {
        return Number(Number(row.price) / 100).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style></style>
